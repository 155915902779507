import { makeStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { media } from 'styled-bootstrap-grid'
import { WebImage } from '@components/images'
import { Icon } from '@components/images/Icon'
import StaticImage from '@components/images/StaticImg'
import { View } from '@components/layout'
import { H3 } from '@components/typography'

const borderRadius = 20

const useMuiStyles = makeStyles(() => ({ paper: { borderRadius } }))

const Content = styled(View)`
  background: ${(props) => props.theme.colors.primary};
  border-radius: ${borderRadius}px;
  overflow: hidden;
  max-width: 400px;
  flex-shrink: 0;
`

const Top = styled(View)`
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  justify-content: space-around;
  align-items: center;
  padding: ${(props) => props.theme.spacing.md}px;
  padding-bottom: ${(props) => props.theme.spacing.md}px;
`

const ImageWrapper = styled(View)`
  border-radius: ${(props) => (props.noImageBorderRadius ? 0 : borderRadius)}px;
  max-width: 80%;
  margin-top: ${(props) => -props.theme.spacing.md}px;
  margin-bottom: ${(props) => props.theme.spacing.md}px;
  overflow: hidden;
  z-index: 1;
  min-height: 125px;

  ${media.sm`
    min-height: 145px;
  `}
`

const PatternImage = styled(StaticImage)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.2;
`

const TopText = styled(H3)`
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  font-weight: bold;
  z-index: 1;
`

const Bottom = styled(View)`
  flex-shrink: 0;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.grayLight};
  border-radius: ${borderRadius}px;
  padding: ${(props) => props.theme.spacing.lg}px;
  padding-top: ${(props) => props.theme.spacing.sm}px;
  align-items: center;
  justify-content: center;
`

const CloseIconWrapper = styled(View)`
  cursor: pointer;
  align-self: stretch;
  align-items: flex-end;
  z-index: 1;
`

const StyledWebImage = styled(WebImage)`
  max-height: 200px;
`

export const MarketingModal = ({
  children,
  onClose,
  noImageBorderRadius,
  staticImgSrc,
  topText,
  description,
  visible,
  webImgSrc,
}) => {
  const classes = useMuiStyles()

  return (
    <Dialog classes={classes} open={visible} onClose={onClose}>
      <Content>
        <Top>
          <PatternImage src="/conversations/pattern.png" />
          <CloseIconWrapper>
            <Icon onClick={onClose} name="times" color="white" />
          </CloseIconWrapper>
          <ImageWrapper noImageBorderRadius={noImageBorderRadius}>
            {webImgSrc ? <StyledWebImage src={webImgSrc} /> : <StaticImage src={staticImgSrc} />}
          </ImageWrapper>
          <TopText>{topText}</TopText>
          {!!description && <TopText>{description}</TopText>}
        </Top>
        <Bottom>{children}</Bottom>
      </Content>
    </Dialog>
  )
}

MarketingModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  topText: PropTypes.string.isRequired,
  staticImgSrc: PropTypes.string,
  webImgSrc: PropTypes.string,
}
