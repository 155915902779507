import noop from 'lodash/noop'
import { redirectSuccess } from 'pages/website/MarketingPages/common/MarketingModal/ModalForm'
import { useUserContext } from '@hooks'
import { MarketingModal } from '../../layout/Modal/MarketingModal/MarketingModal'
import { CaptureForm } from './CaptureForm'

export const LeadCaptureModal = ({
  fieldsHidden,
  formHeading,
  includePhone,
  noImageBorderRadius,
  onClose,
  onSubmitSuccess = noop,
  source,
  staticImgSrc,
  topText,
  visible,
  webImgSrc,
  description,
  redirectOnSuccess,
}) => {
  const { lead } = useUserContext()

  return (
    <MarketingModal
      onClose={onClose}
      visible={visible}
      topText={topText}
      description={description}
      staticImgSrc={staticImgSrc}
      webImgSrc={webImgSrc}
      noImageBorderRadius={noImageBorderRadius}
    >
      <CaptureForm
        includePhone={includePhone}
        fieldsHidden={fieldsHidden}
        heading={formHeading}
        onSubmit={async (vals) => {
          await lead({ ...vals, source })
          !!redirectOnSuccess && redirectSuccess(redirectOnSuccess)
          onClose()
          onSubmitSuccess()
        }}
      />
    </MarketingModal>
  )
}
